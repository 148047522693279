@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Alliance-1";
  src: url("./assets/fonts/alliance-no1-cufonfonts/Degarism\ Studio\ -\ Alliance\ No.1\ Light.otf");
}

@font-face {
  font-family: "Alliance-2";
  src: url("./assets/fonts/alliance-no1-cufonfonts/Degarism\ Studio\ -\ Alliance\ No.2\ Light.otf");
}
body {
  font-family: Alliance-1;
}
.alliance-2 {
  font-family: Alliance-2;
}
.app-container {
  max-width: 1280px;
  margin: 0 auto;
}

.header {
  background: linear-gradient(
    180deg,
    #000,
    rgba(0, 0, 0, 0.65) 50%,
    transparent
  );
}

.gradient-1 {
  background: linear-gradient(180deg, #181818, transparent);
}

.white-glow {
  box-shadow: 0 0 15px #fff;
}

.tv-bg {
  background-image: url("./assets/icons/apple-tv-background.jpg");
}

.starry-night {
  background-image: url("./assets/icons/background.svg");
}

input:checked:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: #fff;
}

footer ul li {
  margin-top: 12px;
  font-size: 16px;
}
.circular {
  /* width: 400px;
  height: 400px;
  border-radius: 100%; */
  overflow: hidden;
  animation: circular ease-in-out;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
@keyframes circular {
  0% {
    border-radius: 0;
    scale: 1;
  }
  100% {
    border-radius: 100%;
    width: 400px;
    height: 400px;
    overflow: hidden;
    scale: 0.8;
  }
}
